import React from 'react'
import Box from './Box'
import Img from 'gatsby-image'
import styled from 'styled-components'
import './Section.scss'
import { CtaProps } from './Cta'
import Colors from '../calendar/util/Colors'

const SectionContainer = styled.div`
  color: #fff;
  background: #000;
  position: relative;
`

const HeroTitle = styled.h1`
  font-size: 2rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: 700;
  position: absolute;
  top: 35%;
  width: 100vw;
  text-align: center;
  @media (min-width: 767px) {
    font-size: 3rem;
  }

  @media (min-width: 767px) {
    font-size: 4rem;
    left: inherit;
    text-align: right;
    right: 2rem;
  }
  @media (min-width: 1024px) {
    font-size: 6rem;
  }
`
export interface SectionProps {
  title: string
  content: any
  background: any
  cta?: CtaProps
  cta2?: CtaProps
  style?: string
  color?: 'dark' | 'light'
}
const Section: React.SFC<SectionProps> = props => {
  const { content, background, style, title, color } = props
  const isHero = style === 'hero'
  return (
    <SectionContainer className="Section">
      {background && <Img fluid={background.fluid} />}
      {isHero && (
        <HeroTitle
          style={{
            color:
              color === 'dark'
                ? '#000'
                : color === 'light'
                ? '#fff'
                : Colors.primary,
          }}
        >
          {title}
        </HeroTitle>
      )}
      {content && !isHero && <Box {...props} />}
    </SectionContainer>
  )
}

export default Section
