import React from 'react'
import Link from './Link'

export interface CtaProps {
  url: string
  title: string
  style?: string
}

const Cta: React.SFC<CtaProps> = props => {
  const { url, title, style } = props
  const modifierClass = style ? `btn-${style}` : 'btn-primary'
  return (
    <Link to={url} className={`btn ${modifierClass} btn-lg`}>
      {title}
    </Link>
  )
}

export default Cta
