import React from 'react'
import styled from 'styled-components'
// import '~video-react/dist/video-react.css'
import { Player, BigPlayButton } from 'video-react'

const VideoContainer = styled.div`
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  margin-bottom:-6px;
  background-color:#000;
`

export function Video({ src, poster }: { src: string; poster: string }) {  
  return (  
    <VideoContainer>
      <video
        autoPlay
        muted
        loop
        preload="yes"
        playsInline
        style={{ width: '100%' }}        
      >
        <source src={src} type="video/mp4" />
      </video>
    </VideoContainer>
    
  )
  
  //return <Player poster={poster} src={src} autoPlay={true} />
}
