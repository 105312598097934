import React from 'react'
import Cta from './Cta'
import styled, { css } from 'styled-components'
import Title from '../ui/Title'
import { SectionProps } from './Section'
import Colors from '../calendar/util/Colors'

const Container = styled<
  {
    styleName: string
    color: string
  },
  'div'
>('div')`
  background-color: #fff;
  opacity: 0.95;
  color: #333;
  padding: 1.5rem;
  font-size: 0.9rem;
  @media (min-width: 992px) {
    font-size: 1rem;
  }

  ${(props) =>
    props.color === 'dark' &&
    css`
      color: #fff;
      background-color: #000;
      opacity: 0.9;
      a :not(.btn) {
        color: ${Colors.primary};
      }
      .btn-outline-primary {
        color: ${Colors.primary};
        &:hover {
          color: ${Colors.text};
        }
      }
    `};

  ${(props) =>
    props.styleName === 'black' &&
    css`
      font-size: 1rem;
      color: #fff;
      background-color: #000;
      opacity: 0.8;
      .btn-outline-primary {
        color: ${Colors.primary};
        &:hover {
          color: ${Colors.text};
        }
      }
    `};

  ${(props) =>
    props.styleName === 'bottom' &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      color: #fff;
      background-color: #000;
      opacity: 0.85;
    `};
  @media (min-width: 992px) {
    padding: 2.5rem;
    width: 50vw;
    max-width: 500px;
    position: absolute;
    top: 20%;
    left: 0;
    ${(props) =>
      props.styleName === 'bottom' &&
      css`
        padding: 1.5rem;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        bottom: 0;
        top: inherit;
        width: 50em;
        max-width: inherit;
      `};
    ${(props) =>
      props.styleName === 'right' &&
      css`
        right: 0;
        left: inherit;
      `};

    ${(props) =>
      props.styleName === 'event' &&
      css`
        position: static;
        width: 100%;
        max-width: inherit;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 10rem;
      `};
  }
  @media (min-width: 768px) {
    ${(props) =>
      props.styleName === 'text' &&
      css`
        position: static;
        width: 100%;
        max-width: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
      `};
  }
`
const Content = styled.div<{ styleName: string }>`
  @media (min-width: 767px) {
    max-width: 800px;
  }
  @media (min-width: 768px) {
    ${(props) =>
      props.styleName === 'event' &&
      css`
        min-width: 600px;
        margin-top: 1rem;
      `};
  }
`
const Box: React.SFC<SectionProps> = (props) => {
  const { title, content, cta, cta2, style = '', color = '' } = props
  const contentHtml = content.childMarkdownRemark.html
  return (
    <Container styleName={style} color={color}>
      {title && <Title>{title}</Title>}
      <Content
        styleName={style}
        dangerouslySetInnerHTML={{
          __html: contentHtml,
        }}
      />
      {cta && !cta2 && <Cta {...cta} />}
      {cta && cta2 && (
        <div className="Section__buttons">
          <Cta {...cta} />
          <Cta {...cta2} />
        </div>
      )}
    </Container>
  )
}

export default Box
