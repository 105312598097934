import React from 'react'
import { Link } from 'gatsby'

const VenueButtons: React.FC<{
  slug: string
  venueLink?: string
  hideLink?: boolean
}> = ({ slug, venueLink, hideLink }) => {
  return (
    <div className="text-center py-5 bg-white">
      {venueLink && (
        <a
          href={venueLink}
          className="btn btn-primary btn-lg mr-3 my-3"
          style={{ fontSize: '2rem' }}
        >
          Reserve
        </a>
      )}
      {!venueLink && !hideLink && (
        <Link
          to={`/reserve?venue=${slug}`}
          className="btn btn-primary btn-lg mr-3 my-3"
          style={{ fontSize: '2rem' }}
        >
          Reserve
        </Link>
      )}
      <Link
        to="/contact"
        className="btn btn-outline-primary btn-lg my-3"
        style={{ fontSize: '2rem' }}
      >
        Contact
      </Link>
    </div>
  )
}

export default VenueButtons
