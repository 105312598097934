import React from 'react'

import styled from 'styled-components'
import Link from './Link'
import Colors from '../calendar/util/Colors'

const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  color: ${Colors.primary};
  &:hover {
    color: ${Colors.primary};
  }
  margin-bottom: 0.4rem;
  @media (min-width: 767px) {
    margin-bottom: 0;
    border-right: 1px solid #dfdfdf;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    line-height: 1.2;
    text-align: center;
    max-width: 200px;
    flex: 1;
    &:last-of-type {
      border-right: none;
    }
  }
`

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 0.1rem;
`
const Venue = styled.div`
  margin-bottom: 0.1rem;
  font-size: 0.8rem;
  color: ${Colors.inactiveTint};
`

const Date = styled.div`
  color: ${Colors.white};
  font-size: 0.8rem;
`

const Event = ({ title, date, url, venue }) => (
  <Container to={url}>
    <Title>{title}</Title>
    <Venue>{venue.name}</Venue>
    <Date>{date}</Date>
  </Container>
)
export default Event
