import React from 'react'
export const MapSalmisaari = () => (
  <iframe
    title="Map"
    width="100%"
    height="450"
    frameBorder="0"
    style={{
      border: 0,
    }}
    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAj7KDxc4wCD93bn6ezs1BSZ349IMUrtMQ&q=Biitsi.fi - ranta sauna baari&zoom=12 "
    allowFullScreen
  />
)

export const MapPasila = () => (
  <iframe
    title="Map"
    width="100%"
    height="450"
    frameBorder="0"
    style={{
      border: 0,
    }}
    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAj7KDxc4wCD93bn6ezs1BSZ349IMUrtMQ&q=Biitsi+Tripla+Helsinki&zoom=12 "
    allowFullScreen
  />
)

export const MapTeras = () => (
  <iframe
    title="Map"
    width="100%"
    height="450"
    frameBorder="0"
    style={{
      border: 0,
    }}
    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAj7KDxc4wCD93bn6ezs1BSZ349IMUrtMQ&q=Teras+Beach+Tallinn+Indoor+Beach&zoom=12 "
    allowFullScreen
  />
)

export const MapKakumae = () => (
  <iframe
    title="Map"
    width="100%"
    height="450"
    frameBorder="0"
    style={{
      border: 0,
    }}
    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAj7KDxc4wCD93bn6ezs1BSZ349IMUrtMQ&q=Haven+Kakumäe&zoom=12 "
    allowFullScreen
  />
)

export const MapVantaa = () => (
  <iframe
    title="Map"
    width="100%"
    height="450"
    frameBorder="0"
    style={{
      border: 0,
    }}
    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAj7KDxc4wCD93bn6ezs1BSZ349IMUrtMQ&q=Biitsi+Vantaa&zoom=12 "
    allowFullScreen
  />
)
