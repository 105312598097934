import 'react-image-gallery/styles/css/image-gallery.css'

import ImageGallery from 'react-image-gallery'
import Img from 'gatsby-image'
import React from 'react'
type Props = {
  images: any
}
export default function ImagesGallery(props: Props) {
  const images = props.images.map((i: any) => {
    const fluid = i.fluid
    return { fluid }
  })  

  return (
    
    <ImageGallery
      items={images}
      showNav={true}
      showThumbnails={true}
      showFullscreenButton={true}
      showPlayButton={true}
      showBullets={true}
      autoPlay={false}
      infinite={false}
      thumbnailPosition="bottom"    
      renderItem={({ fluid }: { fluid: any }) => <Img fluid={fluid} />}
      renderThumbInner={({ fluid }: { fluid: any }) => <Img fluid={fluid} />}
    />
    
  )
}
