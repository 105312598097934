import React from 'react'
import styled from 'styled-components'
import Colors from '../calendar/util/Colors'

const AspectContainer = styled.div`
  padding-bottom: 56.25%;
  position: relative;
`

const ContactContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
`

const ContactContent = styled.div`
  flex: 1;
  overflow: auto;
`

const ContactInner = styled.div`
  padding: 2rem;
  @media (max-width: 1023px) {
    padding: 1rem;
  }
`

const ContactName = styled.div`
  font-size: 2rem;
  font-weight: 200;
  line-height: 1;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  position: relative;
  :after {
    content: '';
    border-bottom: 4px solid ${Colors.primary};
    max-width: 120px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
`

const ContactTitle = styled.div`
  font-size: 1.2rem;
  line-height: 1.1;
  margin-bottom: 1rem;
  @media (max-width: 1023px) {
    font-size: 1rem;
  }
`
const ContactDetail = styled.div`
  font-size: 0.7rem;
  @media (max-width: 767px) {
    font-size: 0.6rem;
  }
  a {
    text-decoration: none;
    font-weight: 400;
  }
`
const formatPhone = (phone: string) => phone.replace(/\s/g, '')

const Contact = ({ title, firstname, lastname, phone, email, image }) => {
  return (
    <AspectContainer>
      <ContactContainer>
        <ContactContent>
          <ContactInner>
            <ContactName>{firstname}</ContactName>
            <ContactTitle>{title}</ContactTitle>
            {lastname && (
              <ContactDetail>
                {firstname} {lastname}
              </ContactDetail>
            )}
            {phone && (
              <ContactDetail>
                <a href={`tel:${formatPhone(phone)}`}>{phone}</a>
              </ContactDetail>
            )}
            {email && (
              <ContactDetail>
                <a href={`mailto:${email}`}>{email}</a>
              </ContactDetail>
            )}
          </ContactInner>
        </ContactContent>
        <div
          style={{
            borderWidth: 1,
            flex: 1,
            borderColor: 'red',
            backgroundImage: `url('${
              image && image.fixed ? image.fixed.src : ''
            }')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '100% 100%',
          }}
        />
      </ContactContainer>
    </AspectContainer>
  )
}

export default Contact
