import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Sections from '../components/Sections'
import { MapTeras, MapKakumae } from '../components/Map'
import Contacts from '../components/Contacts'
import { PageContainer } from '../ui/Components'
import Company from './Company'
import ContactFormButton from '../components/ContactFormButton'
import VenueButtons from '../components/VenueButtons'
import Events from '../components/Events'
import OpenShifts from '../components/OpenShifts'

const Page = ({ data }) => {
  const { contentfulPage, allContentfulStaffMember, allContentfulEvent } = data
  const { sections, content, node_locale, slug } = contentfulPage
  const contacts = allContentfulStaffMember.edges.map((node) => node.node)
  return (
    <Layout locale={node_locale} page={contentfulPage}>
      {!sections && content && (
        <PageContainer
          dangerouslySetInnerHTML={{
            __html: content.childMarkdownRemark.html,
          }}
        />
      )}
      {(slug === 'sport' || slug === 'home') && (
        <>
          <Sections sections={sections.slice(0, 1)} />
          <Events events={allContentfulEvent.edges.map((node) => node.node)} />

          <Sections sections={sections.slice(1, sections.length)} />
          {slug === 'sport' && (
            <Contacts
              contacts={contacts.filter(
                (c) =>
                  c.category === 'coach' ||
                  c.category === 'sales' ||
                  c.category === 'sport'
              )}
            />
          )}
        </>
      )}
      {slug !== 'sport' && slug !== 'home' && sections && (
        <Sections sections={sections} />
      )}
      {slug === 'events' && (
        <Company contacts={contacts.filter((c) => c.category === 'sales')} />
      )}

      {slug === 'kakumae' && (
        <>
          <VenueButtons slug={slug} />
          <MapKakumae />
        </>
      )}
      {(slug === 'about-us' || slug === 'contact') && (
        <>
          <ContactFormButton />
          <Contacts contacts={contacts.filter((c) => c.category === 'sales')} />
          <MapTeras />
        </>
      )}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageQuery($slug: String!, $locale: String!) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      node_locale
      id
      metaTitle
      metaDescription
      slug
      content {
        childMarkdownRemark {
          html
        }
      }
      sections {
        background {
          fluid(maxWidth: 1920) {
            base64
            src
            srcSet
            aspectRatio
            sizes
          }
        }
        video {
          file {
            url
          }
        }
        imageGallery {
          fluid {
            base64
            src
            srcSet
            aspectRatio
            sizes
          }
        }
        title
        style
        color
        component
        content {
          childMarkdownRemark {
            html
          }
        }
        cta {
          url
          title
          style
        }
        cta2 {
          url
          title
          style
        }
      }
    }
    allContentfulStaffMember(
      filter: { node_locale: { eq: $locale } }
      sort: { order: ASC, fields: [orderIndex] }
    ) {
      edges {
        node {
          firstname
          lastname
          email
          phone
          title
          category
          image {
            fixed(width: 266, height: 300) {
              src
            }
          }
        }
      }
    }
    allContentfulEvent(
      filter: { node_locale: { eq: "et" }, done: { ne: true } }
      sort: { order: ASC, fields: [date] }
      limit: 5
    ) {
      edges {
        node {
          title
          date(formatString: "DD.MM.YYYY")
          url
          venue {
            name
          }
        }
      }
    }
  }
`
