import React, { Component } from 'react'
import Event from './Event'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  @media (min-width: 767px) {
    padding: 0;
    margin: 0;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
  }

  background-color: #000;
  color: Colors.white;
`

interface Props {
  events: any
}

export default function Events(props: Props) {
  return (
    <Container>
      {props.events.map(event => (
        <Event {...event} key={event.title} />
      ))}
    </Container>
  )
}
