import React from 'react'
import Contacts from '../components/Contacts'
import ContactFormButton from '../components/ContactFormButton'

const Company: React.FC<{ contacts: any }> = ({ contacts }) => {
  return (
    <>
      <ContactFormButton />
      <Contacts contacts={contacts} />
    </>
  )
}

export default Company
