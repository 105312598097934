import React from 'react'
import Section from './Section'
import { Video } from './Video'
import ImagesGallery from './ImagesGallery'
import Beaches from './Beaches'
const Sections = ({ sections }) => {
  if (!sections) {
    return null
  }
  return sections.map((section, index) => {
    const { component } = section
    if (component) {
      if (component === 'Beaches') {
        return <Beaches key={index} />
      }
    }
    if (section.imageGallery && section.imageGallery.length > 0) {
      return <ImagesGallery key={index} images={section.imageGallery} />
    }
    if (section.video && section.video.file) {
      return (
        <Video
          key={index}
          src={section.video.file.url}
          poster={section.background ? section.background.fluid.src : ''}
        />
      )
    }
    return <Section key={index} {...section} />
  })
}

export default Sections
