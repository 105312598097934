import React from 'react'
import Contact from './Contact'
import styled from 'styled-components'

const StyledContacts = styled.div`
  margin: 0 auto 0 auto;
  max-width: 1400px;

  padding: 5% 5% 10% 5%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    padding: 5% 0% 10% 0%;
  }
  grid-gap: 15px;
`

const Contacts = ({ contacts }) => {
  return (
    <div className="bg-gray">
      <StyledContacts>
        {contacts.map(c => (
          <Contact key={c.email} {...c} />
        ))}
      </StyledContacts>
    </div>
  )
}

export default Contacts
