import React from 'react'
import T from '../calendar/T'

const ContactFormButton: React.FC = () => {
  return (
    <div className="text-center py-5 bg-white">
      <a
        target="_blank"
        href="https://pipedrivewebforms.com/form/54cfafa6b175febd6d448b4023bb39871789477"
        className="btn btn-primary btn-lg"
        style={{ fontSize: '2rem' }}
      >
        {T('Contact')}
      </a>
    </div>
  )
}

export default ContactFormButton
