import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Colors from '../calendar/util/Colors'
import Title from '../ui/Title'

const NameContainer = styled.div`
  padding-top: 1rem;
  text-transform: uppercase;
  color: ${Colors.white};
`

const Column = styled.div`
  margin: 2rem;
  @media (max-width: 767px) {
    margin: 1rem 0;
  }
`
const Beach = ({ name, image, id }) => {
  return (
    <Link to={id} style={{ flex: 1 }}>
      <Column>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <img src={image} className="img-fluid" />
          <div>
            <NameContainer>{name}</NameContainer>
          </div>
        </div>
      </Column>
    </Link>
  )
}

const BeachesList = [
  {
    id: 'pasila',
    name: 'Biitsi Pasila',
    image:
      'https://images.ctfassets.net/pnyokv5ndhzc/5gfabQRBH7UBaEJiOvLYod/afd2b6d77f8d938920dc2b4161738844/1V5A2017_Photo_PasiSalminen_com.jpg?h=400&w=600&q=50&fit=thumb&r=10&bg=rgb:000',
  },
  {
    id: 'salmisaari',
    name: 'Biitsi Salmisaari',
    image:
      'https://images.ctfassets.net/pnyokv5ndhzc/4xar9JtL320Kyi6qSkAgcm/542397c844fab42a5651b1f4ea2a6e60/champagne.jpg?h=400&w=600&q=50&fit=thumb&r=10&bg=rgb:000',
  },
  {
    id: 'tallinna',
    name: 'Teras Beach Tallinna',
    image:
      'https://images.ctfassets.net/pnyokv5ndhzc/4LP0BtQSBwC5OECLO8OAOt/05f79fe65187b250b95ff6e4c68d44e8/CocaCola-Teras-5619.jpg?h=400&w=600&q=50&fit=thumb&r=10&bg=rgb:000',
  },
]

const BeachTitle = styled(Title)`
  text-align: center;
  color: ${Colors.white};
  &:after {
    margin-left: auto;
    margin-right: auto;
    right: 0;
    left: 0;
    width: 100%;
  }
`

const Container = styled.div`
  background-color: ${Colors.black};
  padding: 2rem;
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const Beaches: React.FC<{}> = ({}) => {
  return (
    <Container>
      <BeachTitle>Biitsit</BeachTitle>
      <FlexContainer>
        {BeachesList.map(b => (
          <Beach {...b} key={b.id} />
        ))}
      </FlexContainer>
    </Container>
  )
}

export default Beaches
